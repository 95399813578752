import { Router } from '@reach/router';
import React from 'react';
import Confirmation from '../../components/Checkout/Confirmation';
import ContextProvider from '../../components/ContextProvider';
import PrivateRoute from '../../components/PrivateRoute';

const ConfirmationPage = () => {
  return (
    <ContextProvider>
      <Router>
        <PrivateRoute
          path="/checkout/confirmation/:checkoutId"
          component={Confirmation}
        />
      </Router>
    </ContextProvider>
  );
};

export default ConfirmationPage;
